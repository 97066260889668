import React from "react";
import { graphql, Link } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout/Layout";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import FormComponent from "../components/form";

const AboutPage = ({ data }) => {
  const {
    header,
    donationButton,
    donationTitle,
    donationExcept,
    greeting,
    mission,
    paragraph,
    form
  } = data.contentfulPageAbout;
  return (
    <Layout showHeader={true}>
      <SEO title="About us" />
      <div
        className="container d-flex flex-column align-items-center"
        style={{ paddingTop: "7rem" }}
        id="welcome"
      >
        <div className="row">
          <div className="col">
            <div className="text-center">
              <p className="my-0" style={{ letterSpacing: "4px" }}>
                {greeting}
              </p>
              <h1 className="mb-5">{header}</h1>
            </div>

            <div className="w-75 mx-auto mb-5" style={{ lineHeight: "3" }}>
              <p>{documentToReactComponents(paragraph.json)}</p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="d-flex flex-column align-items-center justify-content-center py-5"
        style={{ backgroundColor: "#000" }}
      >
        <div className="d-flex w-75 justify-content-around text-white flex-wrap">
          <h2
            style={{
              marginBottom: "50px"
            }}
          >
            Our Mission
          </h2>
          <p
            style={{
              lineHeight: "2.5"
            }}
          >
            {documentToReactComponents(mission.json)}
          </p>
        </div>
      </div>
      <div
        style={{
          textAlign: "center",
          backgroundColor: "#000",
          color: "#fff",
          paddingTop: "30px",
          paddingBottom: "100px"
        }}
      >
        <h3
          style={{
            marginBottom: "20px"
          }}
        >
          {donationTitle}
        </h3>
        <p>{donationExcept}</p>
        <div
          style={{
            marginTop: "50px"
          }}
        >
          <Link
            to="/donate"
            style={{
              fontSize: "20px",
              backgroundColor: "#fff",
              color: "#000",
              border: "none",
              padding: "5px 40px"
            }}
          >
            {donationButton}
          </Link>
        </div>
      </div>
      {form && <FormComponent {...form} />}
    </Layout>
  );
};

export const query = graphql`
  {
    contentfulPageAbout {
      form {
        address
        button
        email
        excerpt
        header
        organization
        phone
      }
      header
      donationButton
      donationExcept
      donationTitle
      greeting
      mission {
        json
      }
      paragraph {
        json
      }
    }
  }
`;

export default AboutPage;
